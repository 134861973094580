import React, { useState } from 'react'
import demoImage from '@/img/beach-house-interior-1.jpg'
import Image from 'next/image'
import { cart, cart_details, payment_methods, shipping_methods } from '../data/CheckoutPageData'

const NocodeCheckout = ({
  showStock,
  showShippingCharge,
  showQuantityTextBox,
  showShippingMethod,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [shippingMethod, setShippingMethod] = useState(null)

  const [showError, setShowError] = useState(false)
  const [error_message, setError_message] = useState('')

  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false)
  const [selectShippingAddress, setSelectShippingAddress] = useState(null)
  const [isNewAddress, setIsNewAddress] = useState(false)

  const [selectStorageHubAddress, setSelectStorageHubAddress] = useState(null)

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [count, setCount] = useState(1)

  const selectShippingMethod = (method) => {
    setShippingMethod(method)
  }
  const selectPaymentMethod = (method) => {
    setPaymentMethod(method)
  }

  return (
    <>
      <div className="  mx-auto w-full  grid      gap-4   p-5    ">
        <div className="    ">
          {/* Cart Item */}{' '}
          <div className="bg-[#FEFEFE] rounded-lg     shadow-md">
            <>
              {cart_details.map((cartItem) => {
                return (
                  <div
                    className=" w-full border border-d_primary rounded-lg px-[24px] py-[16px] grid  grid-cols-[100%] justify-between    mb-4"
                    key={cartItem.id}
                  >
                    <div>
                      {showStock && (
                        <p className=" text-xs  font-semibold leading-6 text-d_primary">
                          {cartItem.listing.stock} products in stock
                        </p>
                      )}
                      <p className=" text-base text-black font-semibold mt-[2px]">
                        {cartItem.listing.title}
                      </p>
                      <p className=" mt-[11px] text-secondary text-xs font-medium flex flex-wrap items-center">
                        <span className=" text-xs leading-6 font-medium text-secondary mr-2">
                          {cartItem.listing.list_price.currency}
                        </span>
                        <span className="text-sm  ">{cartItem.listing.list_price.amount}</span>
                      </p>
                    </div>
                    <div className=" w-full mt-6   flex items-center justify-around">
                      <div className="  min-w-[90px] h-[32px] border border-d_primary rounded-[2px]  flex justify-between items-center ">
                        <button
                          onClick={() => count > 1 && setCount(count - 1)}
                          className=" w-[32px] h-[32px] bg-d_primary  rounded-l-sm flex justify-center items-center text-xl leading-6 font-medium text-white"
                        >
                          -
                        </button>
                        <span className=" text-sm leading-4 font-medium text-[#4A4A4A] mx-3">
                          {count}
                        </span>
                        <button
                          onClick={() => setCount(count + 1)}
                          className="w-[32px] h-[32px] bg-d_primary  rounded-r-sm flex justify-center items-center text-xl leading-6 font-medium  text-white "
                        >
                          +
                        </button>
                      </div>
                      <div className="ml-6">
                        <button className="w-[32px] h-[32px] bg-d_primary   flex justify-center items-center text-xl leading-6 font-medium  text-white  rounded">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          </div>
          {showShippingMethod && (
            <div className="mt-6 shadow-lg">
              <div className=" w-full min-h-[100px] bg-[#FEFEFE] rounded-lg  p-5   ">
                <p className="text-d_primary text-xl leading-6 font-medium ">Shipping Method</p>
                <div className=" mt-5 flex items-center flex-wrap">
                  {shipping_methods.map((method) => {
                    return (
                      <button
                        className={
                          shippingMethod?.id !== method.id
                            ? ' min-w-[100px] h-5 px-2   inset-0 py-4 border border-secondary flex items-center justify-between rounded-lg mr-3 mb-3 transition duration-700   hover:border-d_primary hover:text-d_primary'
                            : ' min-w-[100px] h-5 px-2   py-4 border   flex items-center justify-between rounded-lg mr-3 mb-3    border-transparent bg-d_primary text-white  cursor-pointer  ring-2 ring-d_primary ring-offset-2 '
                        }
                        key={method.id}
                        onClick={() => selectShippingMethod(method)}
                      >
                        <span className=" text-sm    font-semibold mr-3">{method.name}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5   ml-3"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          <div className="mt-6 shadow-lg">
            <div className=" w-full bg-[#FEFEFE] rounded-lg p-5   ">
              <p className="text-d_primary text-xl leading-6 font-medium ">Payment Method</p>
              <div className=" mt-5 flex items-center flex-wrap">
                {payment_methods?.map((method) => {
                  return (
                    <button
                      className={
                        paymentMethod?.id !== method.id
                          ? ' min-w-[100px] h-5 px-2  py-4 border border-secondary flex items-center justify-between rounded-lg mr-3 mb-3  transition duration-700    hover:border-d_primary hover:text-d_primary'
                          : ' min-w-[100px] h-5 px-2 px-6 py-4 border   flex items-center justify-between   mr-3 mb-3  border-transparent bg-d_primary text-white rounded-md cursor-pointer  ring-2 ring-d_primary ring-offset-2 '
                      }
                      key={method.id}
                      onClick={() => selectPaymentMethod(method)}
                    >
                      <span className=" text-sm     font-semibold mr-3">{method.name}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5   ml-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        {/* Order Summary */}
        <div className="  shadow-lg">
          <div className=" w-full min-h-[200px] bg-[#FEFEFE] rounded-lg py-[26px] px-6 ">
            <p className="text-d_primary text-xl leading-6 font-medium  mt-3">Order Summary</p>
            <div className="mt-5">
              {cart_details?.map((item) => {
                return (
                  <div
                    className="w-full  bg-[#FFFFFF] rounded-lg shadow-c-sm   overflow-hidden flex  mb-3 "
                    key={item.id}
                  >
                    <div className=" w-[100px] h-[100px] relative mr-3  bg-gray">
                      <Image
                        src={item.listing.images[0]}
                        layout="fill"
                        objectFit="cover"
                        alt="Order Items"
                        priority={true}
                      />
                    </div>
                    <div className=" flex flex-col justify-around py-2">
                      <div>
                        <p className=" text-base  font-semibold text-black">{item.listing.title}</p>
                      </div>
                      <div>
                        <p className=" text-base  font-medium text-secondary ">
                          {item.listing.location.country}
                        </p>
                        <p className=" text-base  font-medium text-secondary ">
                          <span>Quantity :</span> <span>{item.quantity}</span>{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            {showQuantityTextBox && (
              <div className="mt-8">
                {cart_details.map((item) => {
                  return (
                    <div
                      className=" w-full min-h-[57px] bg-[#FFFFFF] border border-d_primary rounded-lg  px-4 py-5 mb-3 "
                      key={item.id}
                    >
                      <p className=" text-sm leading-4 font-semibold text-[#4F4F4F] ">
                        <span>{item.quantity}</span>
                        <span className="ml-2">X</span>
                        <span className="ml-2">{item.listing.title}</span>
                      </p>
                    </div>
                  )
                })}
              </div>
            )}
            <div className="mt-8">
              <p className="text-base leading-6 font-semibold text-secondary flex justify-between items-center mb-2">
                <span>Total</span>{' '}
                <span className=" flex flex-wrap items-center">
                  <span className="text-sm leading-6 font-medium text-secondary mr-2">
                    {cart.list_total?.currency}
                  </span>
                  <span className="text-base leading-6 font-semibold text-gray-600">
                    {cart.list_total?.amount}
                  </span>
                </span>
              </p>
              {showShippingCharge && (
                <p className="text-base leading-6 font-semibold text-secondary flex justify-between items-center mb-2">
                  <span>Shipping</span>{' '}
                  <span className=" flex flex-wrap items-center">
                    <span className="text-sm leading-6 font-medium text-secondary mr-2">
                      {cart.shipping_total?.currency}
                    </span>
                    <span className="text-base leading-6 font-semibold text-gray-600">
                      {cart.shipping_total?.amount}
                    </span>
                  </span>
                </p>
              )}
              <p className="text-xl leading-6 font-semibold text-black flex justify-between items-center">
                <span>Subtotal</span>{' '}
                <span className=" flex flex-wrap items-center">
                  <span className="text-sm leading-6 font-medium text-secondary mr-2">
                    {cart.grand_total?.currency}
                  </span>
                  <span className="text-xl leading-6 font-semibold text-black">
                    {cart.grand_total?.amount}
                  </span>
                </span>
              </p>
            </div>
          </div>
          {
            <div className="flex justify-center  mt-6">
              <button className=" w-5/6 bg-d_primary  rounded-full py-[12px] text-center text-base  text-white flex justify-center items-center font-semibold">
                <span className=" ">Checkout</span>
              </button>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default NocodeCheckout

import React, { useState, useEffect } from 'react'
import { TwitterPicker } from 'react-color'
import CheckoutPageLayout from './CheckoutPage/CheckoutPageLayout.jsx'
import tradlyTheme from '../../../css/tradly_css.json'
import OutsideClickHandler from 'react-outside-click-handler'
import NocodeCheckout from './CheckoutPage/NocodeCheckout.jsx'
import classes from './common.module.scss'
import { Switch } from '@headlessui/react'

const Features = () => {
  const [showStock, setShowStock] = useState(true)
  const [showShippingCharge, setShowShippingCharge] = useState(true)
  const [showShippingMethod, setShowShippingMethod] = useState(true)
  const [showQuantityTextBox, setShowQuantityTextBox] = useState(true)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let root = document.documentElement
      root.style.setProperty('--d_color-primary', '#01544E')
    }
  }, [0])

  return (
    <div className={classes.bg_style} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="grid  md:grid-cols-2 gap-4 max-h-[600px] md:max-h-[410px] overflow-hidden  px-5  md:px-10">
        {/* Colour plate */}
        <div className="relative  bg-white  p-5">
          <div className="flex items-center gap-4">
            <h2 className=" text-base font-semibold text-black   ">Enable stock</h2>
            <Switch
              checked={showStock}
              onChange={() => setShowStock(!showStock)}
              className={`${showStock ? 'bg-primary' : 'bg-gray-400'}
          relative inline-flex flex-shrink-0 h-[20px] w-[42px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              {showStock ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 absolute left-[2px] top-0 bottom-0 my-auto text-bold text-white "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 absolute right-[2px] top-0 bottom-0 my-auto text-bold text-white "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${showStock ? 'translate-x-[21px] bg-white' : 'translate-x-0'}
            pointer-events-none inline-block h-[16px] w-[16px] rounded-full  shadow-lg bg-white transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
          </div>
          <div className="flex items-center gap-4 mt-3">
            <h2 className=" text-base font-semibold text-black   ">Enable shipping method</h2>
            <Switch
              checked={showShippingMethod}
              onChange={() => setShowShippingMethod(!showShippingMethod)}
              className={`${showShippingMethod ? 'bg-primary' : 'bg-gray-400'}
          relative inline-flex flex-shrink-0 h-[20px] w-[42px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              {showShippingMethod ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 absolute left-[2px] top-0 bottom-0 my-auto text-bold text-white "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 absolute right-[2px] top-0 bottom-0 my-auto text-bold text-white "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${showShippingMethod ? 'translate-x-[21px] bg-white' : 'translate-x-0'}
            pointer-events-none inline-block h-[16px] w-[16px] rounded-full  shadow-lg bg-white transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
          </div>
          <div className="flex items-center gap-4 mt-3">
            <h2 className=" text-base font-semibold text-black   ">Enable shipping charge</h2>
            <Switch
              checked={showShippingCharge}
              onChange={() => setShowShippingCharge(!showShippingCharge)}
              className={`${showShippingCharge ? 'bg-primary' : 'bg-gray-400'}
          relative inline-flex flex-shrink-0 h-[20px] w-[42px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              {showShippingCharge ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 absolute left-[2px] top-0 bottom-0 my-auto text-bold text-white "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 absolute right-[2px] top-0 bottom-0 my-auto text-bold text-white "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${showShippingCharge ? 'translate-x-[21px] bg-white' : 'translate-x-0'}
            pointer-events-none inline-block h-[16px] w-[16px] rounded-full  shadow-lg bg-white transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
          </div>
          {/* <div className="flex items-center gap-4 mt-3">
              <h2 className=" text-base font-semibold text-black   ">Show quantity text box </h2>
              <input
                type="checkbox"
                id="toggle"
                checked={showQuantityTextBox}
                onChange={() => setShowQuantityTextBox(!showQuantityTextBox)}
              />
            </div> */}
        </div>

        {/* Component */}
        <div className="max-h-[400px] overflow-auto">
          <NocodeCheckout
            showStock={showStock}
            showShippingCharge={showShippingCharge}
            showQuantityTextBox={showQuantityTextBox}
            showShippingMethod={showShippingMethod}
          />
        </div>
      </div>
    </div>
  )
}

export const FeaturesComponent = () => {
  return (
    <>
      <Features />
    </>
  )
}

import React from 'react'
import classes from './common.module.scss'
import { logo, sign_in_icon, store_icon, wishlist_icon } from './data/imagesUrl'
import HeaderCategories from './HomePageFeature/Header/HeaderCategories'
import SearchBox from './HomePageFeature/SearchBox/SearchBox'
import Image from 'next/image'
import { home_page_data } from './data/HomePageData'
import Categories from './HomePageFeature/Categories/Categories'
import LatestEvent from './HomePageFeature/LatestEvents/LatestEvent'
import Footer from './HomePageFeature/Footer/Footer'
import Header3 from './HomePageFeature/Header/Header3'
import googleplayImage from '@/img/tradly/play-store-image2.png'
import {
  facebook_icon,
  snapchat_icon,
  telegram_icon,
  twitter_icon,
  whatsapp_icon,
  youtube_icon,
} from './data/socialIcons'
import { categories } from './data/HomePageData'

const MobileFeature = () => {
  return (
    <div className={classes.bg_style} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="max-w-[405px] mx-auto max-h-[600px]  md:max-h-[410px] px-5 md:px-10 md:pb-10 pt-0 overflow-auto relative">
        {/* Header */}
        <div className=" sticky  top-0 z-10 ">
          <Header3 />
        </div>
        {/* Body */}
        <div className="bg-[#f6f9ff]   px-[25px]   pt-5 pb-10">
          <div className="">
            <div>
              <div className=" w-full h-[140px] relative rounded-lg overflow-hidden  mb-9">
                <Image
                  src={home_page_data.promo_banners[0].image_path}
                  alt="Banner Image"
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            </div>

            {
              <div>
                <Categories categories={home_page_data.categories} />
              </div>
            }
            {home_page_data.collections?.map((collection) => {
              const scope_type = collection.scope_type
              // if (scope_type === 1 && marketplace_module === 1) {
              //   return (
              //     <div key={Math.random()}>
              //       <StoresForFollow stores={collection} />
              //     </div>
              //   )
              // }
              if (scope_type === 4) {
                return (
                  <div key={Math.random()}>
                    <LatestEvent products={collection} />
                  </div>
                )
              }
            })}
          </div>
        </div>
        {/* footer */}
        <div className="bg-white min-h-[200px]   left-0 bottom-0 px-[25px]  ">
          <div className="w-full h-full py-4 grid grid-cols-2  gap-10  border-b border-[rgba(216, 216, 216, 0.5)]">
            <div className=" col-span-2  ">
              {
                <a className="   w-[180px] h-[50px] relative flex justify-start">
                  <img src={logo} layout="fill" objectFit="contain" alt="logo" />
                </a>
              }

              <a className=" block mt-4 ">
                <button
                  // onClick={() => window.open(general_configs?.app_download_link)}
                  className=" text-base text-[#4F4F4F] font-medium rounded-lg overflow-hidden"
                >
                  <img src={googleplayImage} className="w-[180px]" objectFit="cover" alt="logo" />
                </button>
              </a>

              <div className="mt-4  ">
                <h2 className="text-base font-semibold pb-4">Follow us on social media</h2>
                <div className="flex items-center gap-3">
                  <a>{facebook_icon}</a>

                  <a>{youtube_icon}</a>

                  <a>{twitter_icon}</a>

                  <a>{snapchat_icon}</a>

                  <a>{telegram_icon}</a>

                  <a>{whatsapp_icon}</a>
                </div>
              </div>
            </div>

            <div>
              <p className=" text-base font-semibold pb-4">Categories</p>
              {categories?.map((item, index, array) => {
                return (
                  <div className="mb-4 " key={Math.random()}>
                    {' '}
                    <a>
                      <button
                        // }
                        className={[
                          '  text-[#4F4F4F]    cursor-pointer transition duration-300 hover:text-primary  text-left',
                        ].join(' ')}
                      >
                        {item.name.length > 20 ? item.name.substring(0, 19) + '.' : item.name}
                      </button>
                    </a>
                  </div>
                )
              })}
            </div>

            <div>
              <p className=" text-base font-semibold pb-4">Links</p>

              <a className=" block    pb-4">
                <button
                  // onClick={() => window.open(general_configs?.terms_url)}
                  className=" text-base text-[#4F4F4F] font-medium  text-left"
                >
                  Terms & Conditions
                </button>
              </a>

              <a className=" block    pb-4">
                <button
                  // onClick={() => window.open(general_configs?.privacy_policy_url)}
                  className=" text-base text-[#4F4F4F] font-medium text-left"
                >
                  Privacy Policy
                </button>
              </a>

              <a className=" block    pb-4">
                <button
                  // onClick={() => window.open(general_configs?.support_url)}
                  className=" text-base text-[#4F4F4F] font-medium"
                >
                  Support
                </button>
              </a>

              {/* <div className="   pb-4">
            
          </div> */}
            </div>
          </div>
          <div className="py-2 flex items-center">
            <p className=" text-xs text-secondary mr-4">© All rights reserved</p>

            <a className=" text-xs text-[#4F4F4F] font-medium">Sitemap</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileFeature

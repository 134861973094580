import React from 'react'
import classes from './common.module.scss'
import { logo, sign_in_icon, store_icon, wishlist_icon } from './data/imagesUrl'
import HeaderCategories from './HomePageFeature/Header/HeaderCategories'
import SearchBox from './HomePageFeature/SearchBox/SearchBox'
import Image from 'next/image'
import { home_page_data } from './data/HomePageData'
import Categories from './HomePageFeature/Categories/Categories'
import LatestEvent from './HomePageFeature/LatestEvents/LatestEvent'
import Footer from './HomePageFeature/Footer/Footer'
import googleplayImage from '@/img/tradly/play-store-image2.png'
import {
  facebook_icon,
  snapchat_icon,
  telegram_icon,
  twitter_icon,
  whatsapp_icon,
  youtube_icon,
} from './data/socialIcons'
import { categories } from './data/HomePageData'


const TabletFeature = () => {
  return (
    <div className={classes.bg_style} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="max-w-[768px] h-[700px] mx-auto  px-5 md:px-10 md:pb-10 pt-0 overflow-auto ">
        {/* Header */}
        <div className=" sticky  top-0 z-10">
          <div className="   min-h-[70px] px-[25px]    shadow-c-sm   bg-white top-0    overflow-x-hidden relative">
            <div className="flex pt-[24px]  w-full">
              <div className="  absolute left-0 right-0    flex  justify-center     ">
                <a className=" flex items-center   relative cursor-pointer ">
                  <Image src={logo} height={44} width={250} objectFit="contain" alt="logo" />
                </a>
              </div>
              <div className="   z-[100] ml-auto">
                <div className=" flex items-center justify-between">
                  <div className="  ms:mr-2 ">
                    <a className=" cursor-pointer w-[34px] h-[44px] px-[2px] flex justify-center items-center">
                      {store_icon}
                    </a>
                  </div>
                  <div className="  ms:mr-2  ">
                    <a className="cursor-pointer w-[34px] h-[44px] px-[6px] flex justify-center items-center">
                      {wishlist_icon}
                    </a>
                  </div>
                  <div>
                    <div>
                      <button className=" w-[90px]  bg-primary text-sm text-white   py-[6px] flex items-center justify-center rounded-md ">
                        <span className=" hidden  ms:block "> {sign_in_icon}</span>
                        <span className=" ms:ml-[6px]"> Log In</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 pb-[16px] flex justify-between items-center">
              <div>
                <HeaderCategories />
              </div>
              <div>
                <SearchBox />
              </div>
            </div>
          </div>
        </div>
        {/* Body */}
        <div className="bg-[#f6f9ff]   px-[30px]   pt-5 pb-10">
          <div className="">
            <div>
              <div className=" w-full h-[240px] relative rounded-lg overflow-hidden  mb-9">
                <Image
                  src={home_page_data.promo_banners[0].image_path}
                  alt="Banner Image"
                  layout="fill"
                  objectFit="fill"
                />
              </div>
            </div>

            {
              <div>
                <Categories categories={home_page_data.categories} />
              </div>
            }
            {home_page_data.collections?.map((collection) => {
              const scope_type = collection.scope_type
              // if (scope_type === 1 && marketplace_module === 1) {
              //   return (
              //     <div key={Math.random()}>
              //       <StoresForFollow stores={collection} />
              //     </div>
              //   )
              // }
              if (scope_type === 4) {
                return (
                  <div key={Math.random()}>
                    <LatestEvent products={collection} />
                  </div>
                )
              }
            })}
          </div>
        </div>
        {/* footer */}
        <div className="bg-white min-h-[200px]   left-0 bottom-0 px-[50px]   ">
          <div className="w-full h-full py-4 grid grid-cols-2 md:grid-cols-3 gap-10  border-b border-[rgba(216, 216, 216, 0.5)]">
            <div className=" col-span-2 md:col-span-1">
              {
                <a className="   w-[180px] h-[50px] relative flex justify-start">
                  <img src={logo} layout="fill" objectFit="contain" alt="logo" />
                </a>
              }

              <a className=" block mt-4 ">
                <button
                  // onClick={() => window.open(general_configs?.app_download_link)}
                  className=" text-base text-[#4F4F4F] font-medium rounded-lg overflow-hidden"
                >
                  <img src={googleplayImage} className="w-[180px]" objectFit="cover" alt="logo" />
                </button>
              </a>

              <div className="mt-4  ">
                <h2 className="text-base font-semibold pb-4">Follow us on social media</h2>
                <div className="flex items-center gap-3">
                  <a>{facebook_icon}</a>

                  <a>{youtube_icon}</a>

                  <a>{twitter_icon}</a>

                  <a>{snapchat_icon}</a>
                </div>
              </div>
            </div>

            <div>
              <p className=" text-base font-semibold pb-4">Categories</p>
              {categories?.map((item, index, array) => {
                return (
                  <div className="mb-4 " key={Math.random()}>
                    {' '}
                    <a>
                      <button
                        // }
                        className={[
                          '  text-[#4F4F4F]    cursor-pointer transition duration-300 hover:text-primary  text-left',
                        ].join(' ')}
                      >
                        {item.name.length > 20 ? item.name.substring(0, 19) + '.' : item.name}
                      </button>
                    </a>
                  </div>
                )
              })}
            </div>

            <div>
              <p className=" text-base font-semibold pb-4">Links</p>

              <a className=" block    pb-4">
                <button
                  // onClick={() => window.open(general_configs?.terms_url)}
                  className=" text-base text-[#4F4F4F] font-medium  text-left"
                >
                  Terms & Conditions
                </button>
              </a>

              <a className=" block    pb-4">
                <button
                  // onClick={() => window.open(general_configs?.privacy_policy_url)}
                  className=" text-base text-[#4F4F4F] font-medium"
                >
                  Privacy Policy
                </button>
              </a>

              <a className=" block    pb-4">
                <button
                  // onClick={() => window.open(general_configs?.support_url)}
                  className=" text-base text-[#4F4F4F] font-medium"
                >
                  Support
                </button>
              </a>

              {/* <div className="   pb-4">
            
          </div> */}
            </div>
          </div>
          <div className="py-2 flex items-center">
            <p className=" text-xs text-secondary mr-4">© All rights reserved</p>
            <a className=" text-xs text-[#4F4F4F] font-medium">Sitemap</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabletFeature

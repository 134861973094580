import React, { useState, useEffect } from 'react'
import { TwitterPicker } from 'react-color'
import CheckoutPageLayout from './CheckoutPage/CheckoutPageLayout.jsx'
import tradlyTheme from '../../../css/tradly_css.json'
import OutsideClickHandler from 'react-outside-click-handler'
import NocodeCheckout from './CheckoutPage/NocodeCheckout.jsx'
import classes from './common.module.scss'

const StylingFeature = () => {
  const [colorCode, setColorCode] = useState('#01544E')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let root = document.documentElement
      root.style.setProperty('--d_color-primary', colorCode)
    }
  }, [colorCode])

  const handleChangeComplete = (color) => {
    setColorCode(color.hex)
  }

  return (
    <div className={classes.bg_style} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="grid md:grid-cols-2 gap-3 max-h-[600px]  md:max-h-[410px] overflow-hidden  px-5  md:px-10">
        {/* Colour plate */}
        <div className="relative">
          <h2 className=" text-bse font-semibold text-black mb-2  ">Select Primary Color</h2>
          <button className="flex items-center border border-d_primary h-8 gap-4 rounded-md overflow-hidden ml-2">
            <span className="text-sm text-d_primary ml-2">{colorCode}</span>
            <span className={`w-[70px] h-full  bg-d_primary block`} />
          </button>
          <div className={'block   top-0  mt-3'}>
            <TwitterPicker
              color={colorCode}
              onChangeComplete={(color) => handleChangeComplete(color)}
            />
          </div>
        </div>

        {/* Component */}
        <div className="max-h-[400px] overflow-auto">
          <NocodeCheckout
            showStock={true}
            showShippingCharge={true}
            showQuantityTextBox={true}
            showShippingMethod={true}
          />
        </div>
      </div>
    </div>
  )
}

export const StylingFeatureComponent = () => {
  return (
    <>
      <StylingFeature />
    </>
  )
}

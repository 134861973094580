import React from 'react'
import classes from './common.module.scss'
import { logo, sign_in_icon, store_icon, wishlist_icon } from './data/imagesUrl'
import HeaderCategories from './HomePageFeature/Header/HeaderCategories'
import SearchBox from './HomePageFeature/SearchBox/SearchBox'
import Image from 'next/image'
import { home_page_data } from './data/HomePageData'
import Categories from './HomePageFeature/Categories/Categories'
 import LatestEvent from './HomePageFeature/LatestEvents/LatestEvent'
import Footer from './HomePageFeature/Footer/Footer'

const WebFeature = () => {
  return (
    <div className={classes.bg_style} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="w-full h-[500px]  px-5 md:px-10 md:pb-10 pt-0 overflow-auto">
        {/* Header */}
        <div className=" sticky  top-0 z-10">
          <div className="   min-h-[70px] px-[25px]    shadow-c-sm   bg-white top-0    overflow-x-hidden relative">
            <div className="flex pt-[24px]  w-full">
              <div className="  absolute left-0 right-0    flex  justify-center     ">
                <a className=" flex items-center   relative cursor-pointer ">
                  <Image src={logo} height={44} width={250} objectFit="contain" alt="logo" />
                </a>
              </div>
              <div className="   z-[100] ml-auto">
                <div className=" flex items-center justify-between">
                  <div className="  ms:mr-2 ">
                    <a className=" cursor-pointer w-[34px] h-[44px] px-[2px] flex justify-center items-center">
                      {store_icon}
                    </a>
                  </div>
                  <div className="  ms:mr-2  ">
                    <a className="cursor-pointer w-[34px] h-[44px] px-[6px] flex justify-center items-center">
                      {wishlist_icon}
                    </a>
                  </div>
                  <div>
                    <div>
                      <button className=" w-[90px]  bg-primary text-sm text-white   py-[6px] flex items-center justify-center rounded-md ">
                        <span className=" hidden  ms:block "> {sign_in_icon}</span>
                        <span className=" ms:ml-[6px]"> Log In</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 pb-[16px] flex justify-between items-center">
              <div>
                <HeaderCategories />
              </div>
              <div>
                <SearchBox />
              </div>
            </div>
          </div>
        </div>
        {/* Body */}
        <div className="bg-[#f6f9ff]  px-[25px]      pt-5 pb-10">
          <div className="">
            <div>
              <div className=" w-full h-[240px] relative rounded-lg overflow-hidden  mb-9">
                <Image
                  src={home_page_data.promo_banners[0].image_path}
                  alt="Banner Image"
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            </div>

            {
              <div>
                <Categories categories={home_page_data.categories} />
              </div>
            }
            {home_page_data.collections?.map((collection) => {
              const scope_type = collection.scope_type
              // if (scope_type === 1 && marketplace_module === 1) {
              //   return (
              //     <div key={Math.random()}>
              //       <StoresForFollow stores={collection} />
              //     </div>
              //   )
              // }
              if (scope_type === 4) {
                return (
                  <div key={Math.random()}>
                    <LatestEvent products={collection} />
                  </div>
                )
              }
            })}
          </div>
        </div>
        {/* footer */}
        <div className="bg-white min-h-[200px]   left-0 bottom-0 px-[50px]  2xl:px-[12%] ">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default WebFeature

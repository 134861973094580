import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/dist/client/router'
import { categories } from '../../data/HomePageData'

const HeaderCategories = () => {
   const router = useRouter()

  return (
    <div>
      <div className=" flex items-center">
        {categories?.map((item) => {
          const query =
            item.name !== 'More'
              ? {
                  name: item.name.replace(/\s/g, '-'),
                  category_id: item.id,
                  page: 1,
                }
              : ''

          return (
            <a className="block" key={Math.random()}>
              <p
                className={[
                  ' min-h-[44px] px-3 flex justify-center items-center cursor-pointer transition duration-300 hover:text-primary ',
                  router?.query?.name === item.name.replace(/\s/g, '-') ? 'text-primary' : '',
                ].join(' ')}
              >
                {item.name === 'More'
                  ? item.name
                  : item.name.length > 12
                  ? item.name.substring(0, 11) + '.'
                  : item.name}
              </p>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default HeaderCategories

import demoImage from '@/img/beach-house-interior-1.jpg'
import Image from 'next/image'
import product1_image from '@/img/tradly/air-jordan-1-mid-se-shoes-hHltxp.jpeg'

module.exports = {
  cart: {
    id: 659,
    total: {
      amount: 180,
      currency: 'AUD',
      formatted: 'A$ 180',
    },
    list_total: {
      amount: 80,
      currency: 'AUD',
      formatted: 'A$ 80',
    },
    offer_total: {
      amount: 9.5,
      currency: 'AUD',
      formatted: 'A$ 9.5',
    },
    shipping_total: {
      amount: 100,
      currency: 'AUD',
      formatted: 'A$ 100',
    },
    grand_total: {
      amount: 180,
      currency: 'AUD',
      formatted: 'A$ 180',
    },
  },
  cart_details: [
    {
      id: 900,
      quantity: 1,
      quantity_total_price: {
        amount: 80,
        currency: 'AUD',
        formatted: 'A$ 80',
      },
      quantity_total_offer_price: {
        amount: 4500,
        currency: 'AUD',
        formatted: 'A$ 4500',
      },
      tax_total_offer_price: 0,
      listing: {
        id: 349,
        account_id: 126,
        list_price: {
          amount: 80,
          currency: 'AUD',
          formatted: 'A$ 80',
        },
        category_id: [280],
        currency_id: 89,
        offer_percent: 5,
        stock: 61,
        shipping_charges: {
          amount: 0,
          currency: 'AUD',
          formatted: 'A$ 0',
        },
        images: [product1_image],
        offer_price: {
          amount: 9.5,
          currency: 'AUD',
          formatted: 'A$ 9.5',
        },
        location: {
          city: '',
          state: '',
          country: 'Türkiye',
          locality: '',
          postcode: '',
          country_code: 'tr',
          formatted_address: 'Mil, Akçakale, Şanlıurfa, Güneydoğu Anadolu Bölgesi, Türkiye',
        },
        type: 'events',
        sold: false,
        active: true,
        status: 2,
        created_at: 1638876830,
        start_at: 1638876829,
        end_at: 1638876829,
        likes: 1,
        max_quantity: 10,
        account: {
          id: 126,
          name: 'Missah Store',
          location: {
            city: '',
            state: 'Punjab',
            country: 'India',
            locality: 'Mattaur',
            postcode: '160055',
            country_code: 'in',
            formatted_address: 'Mattaur, Mataur, Sahibzada Ajit Singh Nagar, Punjab, 160055, India',
          },
          unique_name: '',
          description: 'QWERTY',
          total_followers: 4,
          total_listings: 4,
          images: [
            'https://tradly-paas-sandbox.s3.amazonaws.com/images/tradlysocial/98658/62f44434-a608-4e67-ae23-e26fc9f5b8bd.PNG',
          ],
          type: 'accounts',
          active: true,
          status: 2,
          following: false,
          user: {
            email_verified: false,
            blocked: false,
            id: '22800ba8-49eb-4a4b-be63-ec366fb25e9c',
            first_name: 'Deve',
            last_name: 'Saini',
            profile_pic:
              'https://tradly-paas-sandbox.s3.amazonaws.com/images/tradlysocial/30974/b3240714-5368-4424-886a-d792f9031867.PNG',
          },
          category_id: [],
        },
        distance: 0,
        in_cart: true,
        liked: false,
        title: 'Jordan 1 Mid SE',
        description: '',
        coordinates: {
          latitude: 36.8440172,
          longitude: 38.7842206,
        },
      },
    },
  ],
  payment_methods: [
    {
      id: 9,
      name: 'Stripe',
      logo_path: '',
      max_amount: 100000,
      min_amount: 10,
      type: 'stripe',
      channel: 'sdk',
      default: true,
      order_by: 1,
      active: true,
    },
    {
      id: 47,
      name: 'PayU',
      logo_path: '',
      max_amount: 100,
      min_amount: 1,
      type: 'payulatam',
      channel: 'web',
      default: false,
      order_by: 1,
      active: true,
    },
    {
      id: 1,
      name: 'Cash on delivery',
      logo_path: '',
      max_amount: 1000.99,
      min_amount: 10,
      type: 'cod',
      channel: 'cod',
      default: false,
      order_by: 2,
      active: true,
    },
  ],
  shipping_methods: [
    {
      id: 1,
      name: 'Pick Up',
      logo_path: '',
      type: 'pickup',
      default: false,
      active: true,
      order_by: 1,
    },
    {
      id: 119,
      name: 'Storage Hub',
      logo_path: '',
      type: 'storage_hub',
      default: true,
      active: true,
      order_by: 4,
    },
    {
      id: 10,
      name: 'Delivery',
      logo_path: '',
      type: 'delivery',
      default: false,
      active: true,
      order_by: 12,
    },
  ],
}

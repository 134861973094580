import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
 import { useRouter } from 'next/dist/client/router'
import googleplayImage from '@/img/tradly/play-store-image2.png'
import {
  facebook_icon,
  snapchat_icon,
  telegram_icon,
  twitter_icon,
  whatsapp_icon,
  youtube_icon,
} from '../../data/socialIcons'
import { logo } from '../../data/imagesUrl'
import { categories } from '../../data/HomePageData'

const Footer = () => {
  const router = useRouter()

  function getThumbnailImage(file) {
    let filename = file.split('/').pop()
    let fileURl = file.replace(filename, 'thumb_' + filename)
    return fileURl
  }
  return (
    <>
      <div className="w-full h-full py-4 grid grid-cols-2 md:grid-cols-3 gap-10  border-b border-[rgba(216, 216, 216, 0.5)]">
        <div className=" col-span-2 md:col-span-1">
          {
            <a className="   w-[180px] h-[50px] relative flex justify-start">
              <img src={getThumbnailImage(logo)} layout="fill" objectFit="contain" alt="logo" />
            </a>
          }

          <a className=" block mt-4 ">
            <button
              // onClick={() => window.open(general_configs?.app_download_link)}
              className=" text-base text-[#4F4F4F] font-medium rounded-lg overflow-hidden"
            >
              <img src={googleplayImage}  className='w-[180px]'  objectFit="cover" alt="logo" />
            </button>
          </a>

          <div className="mt-4  ">
            <h2 className="text-base font-semibold pb-4">Follow us on social media</h2>
            <div className="flex items-center gap-3">
              <a>{facebook_icon}</a>

              <a>{youtube_icon}</a>

              <a>{twitter_icon}</a>

              <a>{snapchat_icon}</a>

              <a>{telegram_icon}</a>

              <a>{whatsapp_icon}</a>
            </div>
          </div>
        </div>

        <div>
          <p className=" text-base font-semibold pb-4">Categories</p>
          {categories?.map((item, index, array) => {
             return (
               <div className="mb-4 " key={Math.random()}>
                 {' '}
                 <a>
                   <button
                     // }
                     className={[
                       '  text-[#4F4F4F]    cursor-pointer transition duration-300 hover:text-primary  text-left',
                     ].join(' ')}
                   >
                     {item.name.length > 20 ? item.name.substring(0, 19) + '.' : item.name}
                   </button>
                 </a>
               </div>
             )
          })}
        </div>

        <div>
          <p className=" text-base font-semibold pb-4">Links</p>

          <a className=" block    pb-4">
            <button
              // onClick={() => window.open(general_configs?.terms_url)}
              className=" text-base text-[#4F4F4F] font-medium  text-left"
            >
              Terms & Conditions
            </button>
          </a>

          <a className=" block    pb-4">
            <button
              // onClick={() => window.open(general_configs?.privacy_policy_url)}
              className=" text-base text-[#4F4F4F] font-medium"
            >
              Privacy Policy
            </button>
          </a>

          <a className=" block    pb-4">
            <button
              // onClick={() => window.open(general_configs?.support_url)}
              className=" text-base text-[#4F4F4F] font-medium"
            >
              Support
            </button>
          </a>

          {/* <div className="   pb-4">
            
          </div> */}
        </div>
      </div>
      <div className="py-2 flex items-center">
        <p className=" text-xs text-secondary mr-4">© All rights reserved</p>
        
          <a className=" text-xs text-[#4F4F4F] font-medium"  >
            Sitemap
          </a>
       
      </div>
    </>
  )
}

export default Footer

import React, { useState } from 'react'
import { Widont } from '../../Widont'
import { BigText, Caption, IconContainer as div, Link, Paragraph } from '../common'
import iconUrl from '@/img/icons/home/tradly-image.png'
import { Tab } from '@headlessui/react'
import heroIcon from '@/img/tradly/hero_icons/launchwherever.svg'
import { feature1 } from '../tradlyFeatures/feature'
import WebFeature from '../tradlyFeatures/webFeature'
import TabletFeature from '../tradlyFeatures/TabletFeature'
import MobileFeature from '../tradlyFeatures/MobileFeature'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AnyDevices = () => {
  let [categories] = useState([
    {
      id: 1,
      buttonTitle: 'Mobile',
      icon: (
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
        />
      ),
      feature: MobileFeature,
    },
    {
      id: 2,
      buttonTitle: 'tablet',
      icon: (
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
        />
      ),
      feature: TabletFeature,
    },
    {
      id: 3,
      buttonTitle: 'Web',
      icon: (
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      ),
      feature: WebFeature,
    },
  ])

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 ">
      <div>
        <img src={heroIcon} alt="" />
      </div>
      <Caption className="text-color5">Any Apps</Caption>
      <BigText>
        <Widont>Launch wherever you want.</Widont>
      </BigText>
      <Paragraph>
      We prioritize Customer Choice, offering flexibility in app types (web, mobile, tablet, desktop, or custom). Through extensive POC, we've crafted a modular API, design components, text strings, and a versatile front-end stack, enabling your app to thrive across various platforms.
      </Paragraph>
      {/* <Link href="/" color="indigo">
        Learn more<span className="sr-only">, about tradly</span>
      </Link> */}

      <div className="w-full     sm:px-0">
        <Tab.Group>
          <Tab.List className=" mt-10 flex p-1  items-center gap-7 ">
            {categories.map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    'w-[96px] py-2.5 text-sm leading-5 font-medium rounded-lg flex flex-col justify-center items-center gap-6  group outline-none',
                    selected ? ' transition duration-500 text-color5' : 'text-gray-300  hover:text-gray-400'
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-12 w-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {category.icon}
                </svg>
                {/* <img className=" text-red-300:" src={category.icon} alt="" /> */}
                <span className="">{category.buttonTitle}</span>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="pt-10 xl:pt-0 mt-2">
            {categories.map((post, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  'bg-white rounded-xl md:p-3',
                  'focus:outline-none      '
                )}
              >
                {post?.feature}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
}

export default AnyDevices

import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import HeaderCategories from './HeaderCategories'
import OutsideClickHandler from 'react-outside-click-handler'
import Drawer from './Drawer'
import { logo, sign_in_icon, store_icon, wishlist_icon } from '../../data/imagesUrl'

const Header3 = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const drawerOpen = () => {
    const drawer = document.getElementById('sideDrawer')
    drawer.classList.remove('-translate-x-full')
    setIsDrawerOpen(true)
  }
  const closeDrawer = () => {
    const drawer = document.getElementById('sideDrawer')
    setIsDrawerOpen(false)
  }

  return (
    <>
      <div className=" bg-white shadow-c-sm overflow-hidden  relative">
        <div className="w-full px-[16px]   ">
          <div className="flex justify-between items-center py-[16px]  relative">
            <div className="  flex items-center">
              <button className="outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-[13px] cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
              {
                <a>
                  <Image src={logo} height={44} width={150} objectFit="contain" alt="logo" />
                </a>
              }
            </div>
            <div className=" flex items-center justify-between">
              <div className=" hidden   sm:block  ms:mr-2 ">
                <a className=" cursor-pointer w-[34px] h-[44px] px-[2px] flex justify-center items-center">
                  {store_icon}
                </a>
              </div>
              <div className=" ms:mr-2  ">
                <a className="cursor-pointer w-[34px] h-[44px] px-[6px] flex justify-center items-center">
                  {wishlist_icon}
                </a>
              </div>
              <div className="relative">
                <div>
                  <button className=" w-[90px]  bg-primary text-sm text-white   py-[6px] flex items-center justify-center rounded-md ">
                    <span className=" hidden  ms:block "> {sign_in_icon}</span>
                    <span className=" ms:ml-[6px]"> Log In</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header3

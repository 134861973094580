module.exports = {
  categories: [
    {
      id: 1837,
      parent: 0,
      type: 'listings',
      active: true,
      image_path:
        'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/25723/77bb555c-bf89-42f9-b81d-344a23291814.jpeg',
      order_by: 1,
      sub_category: [],
      name: 'ADIDAS',
      hierarchy: [
        {
          id: 1837,
          name: 'ADIDAS',
          level: 1,
        },
      ],
    },
    {
      id: 1839,
      parent: 0,
      type: 'listings',
      active: true,
      image_path:
        'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/61556/63e3c33e-ba29-43cf-8683-ad680ce423bf.jpeg',
      order_by: 1,
      sub_category: [],
      name: 'NIKE',
      hierarchy: [
        {
          id: 1839,
          name: 'NIKE',
          level: 1,
        },
      ],
    },
    {
      id: 1838,
      parent: 0,
      type: 'listings',
      active: true,
      image_path:
        'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/48553/0177d7c3-5a7f-4df6-983e-07c0079a73be.png',
      order_by: 2,
      sub_category: [],
      name: 'AIR JORDAN',
      hierarchy: [
        {
          id: 1838,
          name: 'AIR JORDAN',
          level: 1,
        },
      ],
    },
  ],
  home_page_data: {
    promo_banners: [
      {
        image_path:
          'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/49029/428def36-6936-493b-b242-a546ae46a4f1.jpeg',
        type: 'general',
        content_id: 0,
        value: '',
      },
      {
        image_path:
          'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/77784/18ef494c-c03c-47a4-a5e8-a8312b1c1afb.jpeg',
        type: 'general',
        content_id: 0,
        value: '',
      },
    ],
    categories: [
      {
        id: 1837,
        parent: 0,
        type: 'listings',
        active: true,
        image_path:
          'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/25723/77bb555c-bf89-42f9-b81d-344a23291814.jpeg',
        order_by: 1,
        sub_category: [],
        name: 'ADIDAS',
        hierarchy: [
          {
            id: 1837,
            name: 'ADIDAS',
            level: 1,
          },
        ],
      },
      {
        id: 1839,
        parent: 0,
        type: 'listings',
        active: true,
        image_path:
          'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/61556/63e3c33e-ba29-43cf-8683-ad680ce423bf.jpeg',
        order_by: 1,
        sub_category: [],
        name: 'NIKE',
        hierarchy: [
          {
            id: 1839,
            name: 'NIKE',
            level: 1,
          },
        ],
      },
      {
        id: 1838,
        parent: 0,
        type: 'listings',
        active: true,
        image_path:
          'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/48553/0177d7c3-5a7f-4df6-983e-07c0079a73be.png',
        order_by: 2,
        sub_category: [],
        name: 'AIR JORDAN',
        hierarchy: [
          {
            id: 1838,
            name: 'AIR JORDAN',
            level: 1,
          },
        ],
      },
    ],
    client_version: {
      android: null,
      ios: null,
    },
    collections: [
      {
        id: 'sdc-3',
        scope_type: 4,
        view_type: 'horizontal',
        background_color: '#FFFF',
        background_image:
          'https://firebasestorage.googleapis.com/v0/b/tradly-android.appspot.com/o/flower-garland-decoration-toran-for-happy-diwali-vector-17918107.jpg?alt=media&token=b854d543-eec1-4949-a3bf-e107a77ccd3b',
        title: 'Latest Sneakers',
        listings: [
          {
            id: 518,
            account_id: 223,
            list_price: {
              amount: 710,
              currency: 'DKK',
              formatted: 'kr710',
            },
            category_id: [1838],
            currency_id: 246,
            offer_percent: 0,
            stock: 30,
            shipping_charges: {
              amount: 0,
              currency: 'DKK',
              formatted: 'kr0',
            },
            images: [
              'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/23625/9456d5c5-3de2-4b52-8e74-0089c76287d4.png',
            ],
            offer_price: {
              amount: 710,
              currency: 'DKK',
              formatted: 'kr710',
            },
            location: {},
            type: 'listings',
            sold: false,
            active: true,
            status: 2,
            created_at: 1634748171,
            start_at: 1634748171,
            end_at: 1634748171,
            likes: 0,
            max_quantity: 20,
            account: {
              id: 223,
              name: 'Angela',
              location: {},
              unique_name: '',
              description: 'I own special edition sneakers (Yeez, Air Jordan) ',
              total_followers: 1,
              total_listings: 7,
              images: [
                'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/42917/a25fe9b4-91d1-4f8d-b2d5-0a071020fb1b.png',
              ],
              type: 'accounts',
              active: true,
              status: 2,
              following: false,
              user: {
                email_verified: true,
                blocked: false,
                id: '6d49f2a3-0b10-44bc-b46c-d804001ffec9',
                first_name: 'Angela',
                last_name: 'M',
                profile_pic: '',
                email: 'jk@istrata.co',
                mobile: '',
                dial_code: '',
                created_at: 1634373500,
                updated_at: 1634386699,
              },
              category_id: [],
            },
            distance: 0,
            in_cart: false,
            liked: false,
            title: 'Air Jordan 1 Mid SE',
            description:
              'Washed denim and suede make this classic Air Jordan 1 Mid SE tough to beat. The materials are inspired by the rugged look and texture of workwear.',
          },
          {
            id: 517,
            account_id: 223,
            list_price: {
              amount: 710,
              currency: 'DKK',
              formatted: 'kr710',
            },
            category_id: [1838],
            currency_id: 246,
            offer_percent: 0,
            stock: 25,
            shipping_charges: {
              amount: 0,
              currency: 'DKK',
              formatted: 'kr0',
            },
            images: [
              'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/70844/c748cf5d-9f22-4743-8967-818c3162ac10.png',
            ],
            offer_price: {
              amount: 710,
              currency: 'DKK',
              formatted: 'kr710',
            },
            location: {},
            type: 'listings',
            sold: false,
            active: true,
            status: 2,
            created_at: 1634747994,
            start_at: 1634747994,
            end_at: 1634747994,
            likes: 0,
            max_quantity: 20,
            account: {
              id: 223,
              name: 'Angela',
              location: {},
              unique_name: '',
              description: 'I own special edition sneakers (Yeez, Air Jordan) ',
              total_followers: 1,
              total_listings: 7,
              images: [
                'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/42917/a25fe9b4-91d1-4f8d-b2d5-0a071020fb1b.png',
              ],
              type: 'accounts',
              active: true,
              status: 2,
              following: false,
              user: {
                email_verified: true,
                blocked: false,
                id: '6d49f2a3-0b10-44bc-b46c-d804001ffec9',
                first_name: 'Angela',
                last_name: 'M',
                profile_pic: '',
                email: 'jk@istrata.co',
                mobile: '',
                dial_code: '',
                created_at: 1634373500,
                updated_at: 1634386699,
              },
              category_id: [],
            },
            distance: 0,
            in_cart: false,
            liked: false,
            title: 'Air Jordan 1 High OG',
            description:
              'This Air Jordan 1 High OG brings back the classic from 1985. High-end leather maintains the classic look, while time-tested cushioning keeps you comfortable on the go.',
          },
          {
            id: 516,
            account_id: 223,
            list_price: {
              amount: 510,
              currency: 'DKK',
              formatted: 'kr510',
            },
            category_id: [1838],
            currency_id: 246,
            offer_percent: 0,
            stock: 20,
            shipping_charges: {
              amount: 0,
              currency: 'DKK',
              formatted: 'kr0',
            },
            images: [
              'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/61954/ccdfb1cd-e650-43f0-ba33-8e25a4db7b49.png',
            ],
            offer_price: {
              amount: 510,
              currency: 'DKK',
              formatted: 'kr510',
            },
            location: {},
            type: 'listings',
            sold: false,
            active: true,
            status: 2,
            created_at: 1634747770,
            start_at: 1634747770,
            end_at: 1634747770,
            likes: 0,
            max_quantity: 20,
            account: {
              id: 223,
              name: 'Angela',
              location: {},
              unique_name: '',
              description: 'I own special edition sneakers (Yeez, Air Jordan) ',
              total_followers: 1,
              total_listings: 7,
              images: [
                'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/42917/a25fe9b4-91d1-4f8d-b2d5-0a071020fb1b.png',
              ],
              type: 'accounts',
              active: true,
              status: 2,
              following: false,
              user: {
                email_verified: true,
                blocked: false,
                id: '6d49f2a3-0b10-44bc-b46c-d804001ffec9',
                first_name: 'Angela',
                last_name: 'M',
                profile_pic: '',
                email: 'jk@istrata.co',
                mobile: '',
                dial_code: '',
                created_at: 1634373500,
                updated_at: 1634386699,
              },
              category_id: [],
            },
            distance: 0,
            in_cart: false,
            liked: false,
            title: 'Air Jordan 1 Hi FlyEase',
            description:
              'The Air Jordan 1 Hi FlyEase gives you a quick way to get into your sneakers while maintaining the timeless appeal of the original.Its collar unzips and the top folds open to create a wide entry, making one of the most iconic shoes of all time easier than ever to get on.',
          },
          {
            id: 515,
            account_id: 223,
            list_price: {
              amount: 410,
              currency: 'DKK',
              formatted: 'kr410',
            },
            category_id: [1838],
            currency_id: 246,
            offer_percent: 0,
            stock: 15,
            shipping_charges: {
              amount: 0,
              currency: 'DKK',
              formatted: 'kr0',
            },
            images: [
              'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/34110/2ad0567c-def0-41d2-8e61-5af3c79715f3.png',
            ],
            offer_price: {
              amount: 410,
              currency: 'DKK',
              formatted: 'kr410',
            },
            location: {},
            type: 'listings',
            sold: false,
            active: true,
            status: 2,
            created_at: 1634747576,
            start_at: 1634747576,
            end_at: 1634747576,
            likes: 0,
            max_quantity: 20,
            account: {
              id: 223,
              name: 'Angela',
              location: {},
              unique_name: '',
              description: 'I own special edition sneakers (Yeez, Air Jordan) ',
              total_followers: 1,
              total_listings: 7,
              images: [
                'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/42917/a25fe9b4-91d1-4f8d-b2d5-0a071020fb1b.png',
              ],
              type: 'accounts',
              active: true,
              status: 2,
              following: false,
              user: {
                email_verified: true,
                blocked: false,
                id: '6d49f2a3-0b10-44bc-b46c-d804001ffec9',
                first_name: 'Angela',
                last_name: 'M',
                profile_pic: '',
                email: 'jk@istrata.co',
                mobile: '',
                dial_code: '',
                created_at: 1634373500,
                updated_at: 1634386699,
              },
              category_id: [],
            },
            distance: 0,
            in_cart: false,
            liked: false,
            title: 'Air Jordan XXXV',
            description:
              "The lighter the shoe, the less weight to carry, the faster players can go. Evolving from last year's release, the Air Jordan XXXV features a stabilising Eclipse plate 2.0, visible Zoom Air cushioning and stitched-in Flightwire cables that help lock you in when you lace up. Lightweight and responsive, it's designed to help players get the most from their power and athleticism.\n",
          },
          {
            id: 512,
            account_id: 223,
            list_price: {
              amount: 510,
              currency: 'DKK',
              formatted: 'kr510',
            },
            category_id: [1838],
            currency_id: 246,
            offer_percent: 0,
            stock: 5,
            shipping_charges: {
              amount: 0,
              currency: 'DKK',
              formatted: 'kr0',
            },
            images: [
              'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/14810/7670985c-644f-4e65-ae3a-5e4ed846234b.png',
            ],
            offer_price: {
              amount: 510,
              currency: 'DKK',
              formatted: 'kr510',
            },
            location: {},
            type: 'listings',
            sold: false,
            active: true,
            status: 2,
            created_at: 1634374656,
            start_at: 1634722167,
            end_at: 1634722167,
            likes: 0,
            max_quantity: 20,
            account: {
              id: 223,
              name: 'Angela',
              location: {},
              unique_name: '',
              description: 'I own special edition sneakers (Yeez, Air Jordan) ',
              total_followers: 1,
              total_listings: 7,
              images: [
                'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/42917/a25fe9b4-91d1-4f8d-b2d5-0a071020fb1b.png',
              ],
              type: 'accounts',
              active: true,
              status: 2,
              following: false,
              user: {
                email_verified: true,
                blocked: false,
                id: '6d49f2a3-0b10-44bc-b46c-d804001ffec9',
                first_name: 'Angela',
                last_name: 'M',
                profile_pic: '',
                email: 'jk@istrata.co',
                mobile: '',
                dial_code: '',
                created_at: 1634373500,
                updated_at: 1634386699,
              },
              category_id: [],
            },
            distance: 0,
            in_cart: false,
            liked: false,
            title: 'iotlet',
            description: '',
          },
          {
            id: 510,
            account_id: 223,
            list_price: {
              amount: 231,
              currency: 'DKK',
              formatted: 'kr231',
            },
            category_id: [1838],
            currency_id: 246,
            offer_percent: 10,
            stock: 2,
            shipping_charges: {
              amount: 0,
              currency: 'DKK',
              formatted: 'kr0',
            },
            images: [
              'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/15696/ce0bc373-e8c5-4cf9-86c1-ebc0cf847182.png',
            ],
            offer_price: {
              amount: 23.1,
              currency: 'DKK',
              formatted: 'kr23.1',
            },
            location: {},
            type: 'listings',
            sold: false,
            active: true,
            status: 2,
            created_at: 1634374434,
            start_at: 1634374434,
            end_at: 1634374434,
            likes: 0,
            max_quantity: 20,
            account: {
              id: 223,
              name: 'Angela',
              location: {},
              unique_name: '',
              description: 'I own special edition sneakers (Yeez, Air Jordan) ',
              total_followers: 1,
              total_listings: 7,
              images: [
                'https://tradly-paas-sandbox.s3.amazonaws.com/images/sneakers/42917/a25fe9b4-91d1-4f8d-b2d5-0a071020fb1b.png',
              ],
              type: 'accounts',
              active: true,
              status: 2,
              following: false,
              user: {
                email_verified: true,
                blocked: false,
                id: '6d49f2a3-0b10-44bc-b46c-d804001ffec9',
                first_name: 'Angela',
                last_name: 'M',
                profile_pic: '',
                email: 'jk@istrata.co',
                mobile: '',
                dial_code: '',
                created_at: 1634373500,
                updated_at: 1634386699,
              },
              category_id: [],
            },
            distance: 0,
            in_cart: false,
            liked: false,
            title: 'Jordan 4 Retro',
            description:
              "Originally released in 2006, the Air Jordan 4 Retro Lightning (2021) is one of the most renowned retro Jordan 4 colorways in Jordan Brand's catalog. It features a Tour Yellow Durabuck upper with black netted panels and dark grey eyelets. On the tongue, a black Jordan",
          },
        ],
      },
      {
        scope_type: 3,
        title: 'Invite your friends to Sneakers Hub',
        description: 'Buy and Sell with Sneakers Hub',
      },
    ],
  },
}

import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/dist/client/router'
import demoImage from '@/img/beach-house-interior-1.jpg'

import CartItemBox from './Cart/CartItemBox/CartItemBox'
import OrderSummary from './Cart/OrderSummary/OrderSummary'
import PaymentMethod from './Cart/PaymentMethods/PaymentMethod'
import ShippingMethod from './Cart/ShippingMethods/ShippingMethod'

import AddressForm from './Cart/AddressForm/AddressForm'
import ShippingAddresses from './Cart/ShippingAddresses/ShippingAddresses'
import StorageHubAddresses from './Cart/StorageHubAddress/StorageHubAddresses'
import { cart, cart_details, payment_methods, shipping_methods } from '../data/CheckoutPageData'

const CheckoutPageLayout = () => {
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [shippingMethod, setShippingMethod] = useState(null)

  const [showError, setShowError] = useState(false)
  const [error_message, setError_message] = useState('')

  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false)
  const [selectShippingAddress, setSelectShippingAddress] = useState(null)
  const [isNewAddress, setIsNewAddress] = useState(false)

  const [selectStorageHubAddress, setSelectStorageHubAddress] = useState(null)

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

 
  const isError = false
  const errorMessage = ''
  const isFetching = false
  const isCheckoutFetching = false
  const isSuccess = false



  return (
    <>
      <div className="   mx-auto w-full   max-h-[490px] overflow-auto   grid  md:grid-cols-2 lg:grid-cols-3 gap-4   p-5 md:p-10 ">
        <div className="   lg:col-span-2">
          <div className="bg-[#FEFEFE] rounded-lg  md:py-12 md:px-9 shadow-md">
            <CartItemBox cart={cart} cart_details={cart_details} />
          </div>
          <div className="mt-6 shadow-lg">
            <ShippingMethod
              shipping_methods={shipping_methods}
              shippingMethod={shippingMethod}
              setShippingMethod={setShippingMethod}
            />
          </div>

          <div className="mt-6 shadow-lg">
            <PaymentMethod
              payment_methods={payment_methods}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </div>
        </div>
        <div className="  shadow-lg">
          <OrderSummary cart={cart} cart_details={cart_details} />
          {
            <div className="flex justify-center  mt-6">
              <button className=" w-5/6 bg-primary  rounded-full py-[12px] text-center text-base  text-white flex justify-center items-center font-semibold">
                <span className=" ">Checkout</span>
              </button>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default CheckoutPageLayout

import { site_url } from 'constant/url'
import { useRouter } from 'next/router'
import React from 'react'

function Cta() {
  const router = useRouter()
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          {/* CTA box */}
          <div
            className="relative bg-gray-100 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden"
            data-aos="zoom-y-out"
          >
            {/* Background illustration */}
            <div
              className="absolute right-0 bottom-0 pointer-events-none hidden lg:block"
              aria-hidden="true"
            >
              <svg width="428" height="328" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient
                    cx="35.542%"
                    cy="34.553%"
                    fx="35.542%"
                    fy="34.553%"
                    r="96.031%"
                    id="ni-a"
                  >
                    <stop stop-color="#34CDB1" stop-opacity="0.25" />
                    <stop offset="1" stop-color="#34CDB1" stop-opacity="0" />
                  </radialGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <g fill="#34CDB1">
                    <ellipse fillOpacity=".04" cx="185" cy="15.576" rx="16" ry="15.576" />
                    <ellipse fillOpacity=".24" cx="100" cy="68.402" rx="24" ry="23.364" />
                    <ellipse fillOpacity=".12" cx="29" cy="251.231" rx="29" ry="28.231" />
                    <ellipse fillOpacity=".64" cx="29" cy="251.231" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".12" cx="342" cy="31.303" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".48" cx="62" cy="126.811" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".12" cx="78" cy="7.072" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".64" cx="185" cy="15.576" rx="6" ry="5.841" />
                  </g>
                  <circle fill="url(#ni-a)" cx="276" cy="237" r="200" />
                </g>
              </svg>
            </div>
            <div className="flex flex-col lg:flex-row justify-between items-center">
              {/* CTA content */}
              <div className="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
                <h3 className="h3 text-black mb-2">
                  {' '}
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                    Simple and FREE.
                  </span>{' '}
                  Launch your project today!
                </h3>
                <p className="text-black text-lg opacity-75">
                  - Launch Marketplaces, Online Storefronts, Booking Apps, Classifieds, Directories,
                  Partner Portals, App Stores, etc.
                  <br /> - Tradly pricing starts with ZERO. You pay only when you grow.
                  <br /> - Free No-code Integrations, No-Code Editor
                </p>
              </div>

              {/* CTA button */}
              <div>
                <a className="btn whitespace-nowrap  bg-primary text-white px-20 " href={`/signup`}>
                  SIGN UP NOW
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cta

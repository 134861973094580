import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

const Button = ({ title, url }) => {
  const router = useRouter()
  const btn_handler = () => {
    if (url) {
      router.push(url)
    } else {
      console.log('Click')
    }
  }
  return (
    <a
      href={url}
      className="btn_outline hover:text-white border border-gray-900 hover:bg-gray-800 w-full sm:w-auto"
    >
      {title}
    </a>
  )
}

export default Button

// className="btn_outline hover:text-white border border-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"

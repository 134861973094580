import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { useRouter } from 'next/dist/client/router'
import { logo } from '../../data/imagesUrl'
import SearchBox from '../SearchBox/SearchBox'
import { categories } from '../../data/HomePageData'

const Drawer = () => {
  return (
    <div>
      <div className="    left-0 right-0    flex-col  justify-center     mb-2">
        {logo && (
          <a className=" block flex items-center   relative cursor-pointer py-4">
            <Image src={logo} height={44} width={250} objectFit="contain" alt="logo" />
          </a>
        )}

        <div className="pb-[10px] px-2">
          <SearchBox />
        </div>
      </div>
      <div>
        {categories?.map((item) => {
          return (
            <div key={item.id}>
              <div
                className={[
                  'w-full h-12 flex   items-center     cursor-pointer  hover:bg-primary_light mb-1',
                  
                ].join(' ')}
              >
                <p className={[' text-sm font-semibold   ml-5 '].join(' ')}>{item.name}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Drawer

/* eslint-disable no-unused-expressions */
import { Tab } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const feature1 = () => {
  return (
    <>
      {/* <div className="pb-12 md:pb-16">
        <img
          src={require('../../../img/tradly/features-top-image.png').default}
          width="1104"
          height="325"
          alt="Features top"
        />
      </div> */}
       
    </>
  )
}
